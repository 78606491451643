<template>
  <el-dialog :visible.sync="openDialog" title="不通过审核" width="500px" >
    <el-form v-loading="loading" size="medium" class="medium-form" :rules="rules" :model="form" label-width="65px" ref="reasonContent" @submit.native.prevent>
      <el-form-item prop="content" label="原因：">
        <el-input type="textarea" :maxlength="20" show-word-limit v-focus
                  v-model="form.content" placeholder="请输入内容" style="min-height: 120px;" />
        <p class="info"><i class="el-icon-info" />将通过订阅消息、系统消息发送通知</p>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="close" size="small">取消</el-button>
      <el-button type="primary" size="small" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { uncheck } from '../../api/experience-list'
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    selectArr: {
      type: Array,
      default: []
    },
    updateList: {
      type: Function
    },
    formFilter: {
      type: Object,
      default: {}
    },
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      form: {
        content: ''
      },
      rules: {
        content: {required: true, message: '请输入原因',tagger: 'blur'}
      },
      rowId: null,
      loading: false
    }
  },
  methods: {
    // 确定
    submit() {
      this.loading = true;
      this.rowId = this.id;
      uncheck({
        id: this.rowId || this.selectArr,
        reason: this.form.content
      }).then(res => {
        this.loading = false
        this.$message.success('操作成功');
        this.rowId = null
        this.openDialog = false;
        this.resetForm()
        this.updateList({...this.formFilter})
      }).catch(err => this.loading = false)
    },
    // 取消
    close() {
      this.openDialog = false
      this.resetForm()
    },
    //重置表单
    resetForm() {
      this.$refs["reasonContent"].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
}
</script>

<style lang="scss" scoped>

</style>