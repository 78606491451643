<template>
  <div class="list-page">
    <SourceScreen :filterForm="filterForm" :uploadFilter="ok"></SourceScreen>
    <list-tabs
      v-model="filterForm.tab"
      :isLoading="loading"
      :tabs="tabArr"
      @tab-click="handleChangeTab"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button
          v-if="filterForm.tab == 'deleted'"
          size="small"
          type="primary"
          @click="handleReduct(-1)"
          >还原
        </el-button>
        <el-button
          v-if="filterForm.tab == 'normal'"
          @click="pass"
          size="small"
          type="success"
          >通过</el-button
        >
        <el-button
          v-if="filterForm.tab == 'normal'"
          @click="nopass"
          size="small"
          type="warning"
          >不通过</el-button
        >
        <el-button size="small" type="danger" @click="handleDel(-1)"
          >删除</el-button
        >
      </div>
      <el-table
        :data="list"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        class="thead-light"
      >
        <el-table-column type="selection"></el-table-column>
        <!-- <el-table-column prop="user_id" label="会员ID"></el-table-column> -->
        <el-table-column
          prop="name"
          label="用户名称"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.nickname }}</template>
        </el-table-column>
        <el-table-column
          prop=""
          label="会内职务"
          min-width="250"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.title_name }} </template>
        </el-table-column>
        <!-- <el-table-column prop="join_count_text" min-width="120" label="会内职务" show-overflow-tooltip></el-table-column>-->
        <el-table-column
          prop="content"
          min-width="320"
          label="内容"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="comments_count"
          label="评论量"
          sortable="custom"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="like_count"
          label="点赞量"
          sortable="custom"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="发布时间"
          min-width="200"
          sortable="custom"
        ></el-table-column>
        <el-table-column prop="check_status" label="审核状态" min-width="200">
          <template slot-scope="{ row }">
            <list-status-text
              :type="
                row.check_status === 0
                  ? 'warning'
                  : row.check_status === 2
                  ? 'success'
                  : 'danger'
              "
              :text="
                row.check_status === 0
                  ? '待审核'
                  : row.check_status === 2
                  ? '已通过'
                  : '未通过'
              "
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="210">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="filterForm.tab == 'normal'"
              @click="pass(scope.row)"
              >通过</el-button
            >
            <el-button
              type="text"
              v-if="filterForm.tab == 'normal'"
              @click="nopass(scope.row)"
              >不通过</el-button
            >
            <el-button @click="EditRow(scope.row.id)" type="text"
              >查看</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-dropdown
              style="height: 40px; line-height: 40px"
              @command="handleDropdownSelect($event, scope.$index)"
            >
              <span style="color: #3576ff" class="el-dropdown-link">更多</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="modify"
                  v-if="filterForm.tab != 'deleted'"
                  >修改数据</el-dropdown-item
                >
                <el-dropdown-item
                  command="restore"
                  v-if="filterForm.tab == 'deleted'"
                  >还原</el-dropdown-item
                >
                <el-dropdown-item command="delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 修改数据弹窗 -->
    <el-dialog
      :visible="showTransfer"
      title="修改数据"
      width="504px"
      @close="showTransfer = false"
      class="dialog-vertical"
    >
      <el-table class="thead-light" :data="updateOperate" stripe>
        <el-table-column prop="title"></el-table-column>
        <el-table-column prop="origin" label="用户数据"></el-table-column>
        <el-table-column prop="virtual" label="运营修改">
          <template slot-scope="scope">
            <el-input
              v-model="virtuals[scope.$index]"
              class="w-60"
              type="number"
              min="0"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="display" label="显示数据">
          <template slot-scope="scope">{{
            Number(virtuals[scope.$index]) + scope.row.origin
          }}</template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="showTransfer = false" size="medium">取消</el-button>
        <el-button @click="getUpdateSava" size="medium" type="primary"
          >确定</el-button
        >
      </template>
    </el-dialog>
    <!-- 不通过原因弹窗 -->
    <no-pass-reason
      v-model="showNoPass"
      :selectArr="selectArr"
      :id="rowId"
      :updateList="getIndexList"
      :formFilter="filterForm"
    ></no-pass-reason>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    ></Pagination>
  </div>
</template>

<script>
import {
  indexList,
  softDelete,
  Delete,
  getPutBack,
  getUpdateVirtual,
  check,
} from "../../api/experience-list";
import SourceScreen from "../../components/ExperienceList/SourceScreen";
import NoPassReason from "../../components/ExperienceList/NoPassReason";
import Pagination from "@/base/components/Default/Pagination";
import ListStatusText from "@/base/components/List/ListStatusText";

export default {
  components: {
    SourceScreen,
    Pagination,
    ListStatusText,
    NoPassReason,
  },

  data() {
    return {
      loading: false,
      showTransfer: false,
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //用户名
        // order_by: '', //排序
        // is_desc: '', // 排序
        sign_type: -1, //	报名类型
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
        position_id: "",
        id: 0,
      },
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {},
      list: [],
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      selectArr: [],
      row_id: 0,
      updateOperate: [],
      virtuals: [0, 0],
      showNoPass: false, //不通过原因弹窗
      rowId: null,
    };
  },
  created() {
    this.filterForm.id = this.$route.params.id;
    this.getIndexList(this.filterForm);
  },
  methods: {
    EditRow(e) {
      this.$router.push({
        name: "ExperienceDetail",
        params: { id: e },
      });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    sortChange() {},
    getUpdateSava() {
      let e = {
        id: this.row_id,
        virtual_like_num: Number(this.virtuals[0]),
        virtual_comment_num: Number(this.virtuals[1]),
      };
      getUpdateVirtual(e).then((res) => {
        this.showTransfer = false;
      });
    },
    //页面切换
    changPage(data) {
      this.filterForm.page_size = data.page_size;
      const pageData = { ...this.filterForm, page: data.page };
      this.getIndexList(pageData);
    },
    // 删除
    handleDel(e) {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        if (this.filterForm.tab == "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      }
      if (e != -1) {
        if (this.filterForm.tab == "normal") {
          this.$msgbox
            .confirm("确定要将此数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: e });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将此数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: e });
            })
            .catch((err) => {});
        }
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab === "normal") {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.getIndexList({
              ...this.filterForm,
              page: this.pageData.current_page,
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.getIndexList({
              ...this.filterForm,
              page: this.pageData.current_page,
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct(e) {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      }
      if (e != -1) {
        this.$msgbox
          .confirm("确定要将此数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: e });
          })
          .catch((err) => {});
      }
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      getPutBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getIndexList(requestData) {
      this.list = [];
      this.pageData = {};
      this.loading = true;
      indexList(requestData)
        .then((res) => {
          const { data } = res;
          this.list = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 筛选回调
    ok(e) {
      // const pageData = {};
      this.filterForm = { ...e, page: 1 };
      this.getIndexList(this.filterForm);
    },
    // 通过
    pass(row) {
      if (row.id) {
        this.$msgbox
          .confirm("是否通过选中心得", "提示", {
            type: "info",
          })
          .then((res) => {
            check({ id: row.id })
              .then((res) => {
                this.getIndexList({
                  ...this.filterForm,
                  page: this.pageData.current_page,
                });
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => {});
      } else {
        if (!this.selectArr.length) {
          this.$message.info("请选择数据项");
        } else {
          this.$msgbox
            .confirm("是否通过选中心得", "提示", {
              type: "info",
            })
            .then((res) => {
              check({ id: this.selectArr })
                .then((res) => {
                  this.getIndexList({
                    ...this.filterForm,
                    page: this.pageData.current_page,
                  });
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => {});
        }
      }
    },
    // 不通过
    nopass(row) {
      if (row.id) {
        this.showNoPass = true;
        console.log(row.id);
        this.rowId = row.id;
      } else {
        if (!this.selectArr.length) {
          this.$message.info("请选择数据项");
        } else {
          this.rowId = null;
          this.showNoPass = true;
        }
      }
    },
    //tab栏切换
    handleChangeTab(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          keyword: "", //用户名
          // order_by: '', //排序
          // is_desc: '', // 排序
          sign_type: -1, //	报名类型
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页多少条数据
          position_id: "",
          id: this.$route.params.id,
        };
      }
      this.getIndexList(this.filterForm);
    },

    handleDropdownSelect(e, index) {
      let single = this.list[index];
      this.row_id = single.id;
      switch (e) {
        case "modify":
          this.updateOperate = [];
          this.updateOperate.push(
            {
              title: "点赞量",
              origin: single.like_count,
              virtual: single.virtual_like_num,
              display: single.like_count + single.virtual_like_num,
            },
            {
              title: "评论量",
              origin: single.comments_count,
              virtual: single.virtual_comment_num,
              display: single.comments_count + single.virtual_comment_num,
            }
          );

          this.virtuals[1] = single.virtual_comment_num;
          this.virtuals[0] = single.virtual_like_num;
          this.showTransfer = true;
          break;
        case "delete":
          this.handleDel(this.row_id, this.filterForm.tab === "deletes");
          break;
        case "restore":
          this.handleReduct(this.row_id);
          break;
      }
    },
  },
};
</script>
